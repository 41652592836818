<script>
import { VDataTable } from "vuetify/lib";

export default {
  name: "WxDataTable",
  extends: VDataTable,
  props: {
    headerProps: {
      type: Object,
      default() {
        return { sortByText: this.$t("wxDataTable.sortBy_mobileHeader") };
      },
    },
  },
};
</script>

<style lang="scss">
// This NOT SCOPED style loads for the entire site
.v-data-table:not(.wx-panel) {
  &,
  &.theme--dark,
  &.theme--light {
    background-color: var(--color-base-background);
  }
}
</style>
<style lang="scss" scoped>
.v-data-table {
  ::v-deep .v-data-table__wrapper {
    &:last-child {
      padding-bottom: calc(var(--box-padding-admin) / 2);
    }
    table {
      table-layout: fixed;

      & > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
      & > thead > tr:last-child > th {
        border-bottom-color: var(--color-border-theme);
      }
      // class: "col-actions two-btn-wide"
      th.two-btn-wide {
        > span {
          display: inline-block;
          min-width: 70px; // match 2 small buttons side-by-side
          text-align: center;
        }
      }

      /** - - - - - - - - - - - - - - - - - - - -
       * isMobile
       * Each <tr> is stack vertically and thead's <th>
       * and tbody's <td> are displayed as <div>s
       */
      .v-data-table-header-mobile {
        > tr:first-child > th {
          height: auto; // `sort by` row
        }
      }
      .v-data-table__mobile-row {
        &:last-child {
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        .v-data-table__mobile-row__cell {
          .v-btn:not(:first-child) {
            margin-left: 1rem;
          }
        }
        &:last-child {
          border-bottom: 2px solid var(--color-border-theme) !important;
        }
        // Large mobile screens (phone horizontal, tablet vertical)
        @media ($wx-sm-min) {
          &:not(:first-child) {
            border-top: thin dotted var(--color-border-contrast-theme);
          }
        }
      }
    }
  }

  /** - - - - - - - - - - - - - - - - - - - -
   * Worximity Custom Style
   * requires one of the 2 following classes
   */
  &.wx-panel,
  &.wx-panel--child {
    ::v-deep table {
      // vertical alignment
      th:first-child,
      td:first-child {
        padding-left: 0 !important;
      }
      th,
      td {
        padding-right: 0 !important;
      }
      // text-truncate in narrow cells
      td {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .v-data-footer {
        border-top: 1px solid var(--color-border-theme);
      }

      // isMobile
      .v-data-table-header-mobile {
        &.v-data-table-header {
          width: 100%;

          & > tr:last-child {
            th {
              width: 100%;
              border-bottom: 2px solid var(--color-border-theme) !important;
            }
          }

          // `Sort by`
          .v-select.v-text-field {
            min-width: 9rem;
            max-width: calc(100% - 8px); // prevent horizontal scrollbar at the bottom of table
            margin: 0 auto var(--wx-panel-container-padding);
            padding-top: 0;

            .v-label {
              margin-left: 0.5rem;
              font-weight: 400;
            }
            &.v-input--is-focused,
            &.v-input--is-label-active {
              .v-label {
                top: 0.015rem;
                margin-left: 0;
              }
            }

            .v-input__control {
              .v-input__slot {
                background-color: var(--color-control-background-theme);
                border-top-right-radius: var(--border-radius-form-elements);
                border-top-left-radius: var(--border-radius-form-elements);

                // hide border-bottom
                &::before,
                &::after {
                  display: none;
                  border-color: transparent !important;
                }

                .v-chip {
                  &.v-chip--clickable {
                    background-color: var(--color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // Fix black backgrounds in DarkMode
  &.wx-panel {
    background-color: var(--color-element-layer1);

    @media ($wx-lg-min) {
      padding: calc(var(--box-padding-admin) / 4) var(--box-padding-admin);
    }
  }
  // if child of a `wx-panel` container
  @at-root .wx-panel #{&} {
    background-color: transparent;
  }
}
</style>
